<template>
    <div>
        <CRow>
            <CCol lg="6">
                <CCardBody>
                    <CDataTable
                            :items="apiDatas"
                            :fields="fields"
                            column-filter
                            table-filter
                            items-per-page-select
                            :items-per-page="50"
                            sorter
                            pagination
                            clickable-rows
                    >
                        <template #actions="{ item }">
                            <td>
<!--                                <CButton @click="removeTime(item)" color="primary">Удалить</CButton>-->
                            </td>
                        </template>
                    </CDataTable>
                </CCardBody>
            </CCol>
            <CCol lg="6">
                <CCard>
                    <CCardHeader>
                        <strong>Добавить Режим</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CForm @submit.prevent="createGrs()">
                            <CInput
                                    type="hidden"
                                    name="action"
                                    v-model="form.action"
                                    required
                            />
                            <CInput
                                    type="time"
                                    label="Введите Режим"
                                    v-model="form.name"
                                    required
                            />


                            <label>Данные для ввода в режиме</label>
                            <div v-for="(elem) in dataTypes"  :key="elem.val" class="form-check">
                                <input type="checkbox" :id="elem.val" :value="elem.val" v-model="form.data_types" class="form-check-input">
                                <label :for="elem.val" class="form-check-label">{{elem.name}}</label>
                            </div>
                            <br>
                            <CButton type="submit" size="sm" color="primary"><CIcon name="cil-check-circle"/> Добавить</CButton>
                        </CForm>
                    </CCardBody>
                    <CCardFooter>
                    </CCardFooter>
                </CCard>
            </CCol>

        </CRow>
    </div>
</template>

<script>
    import axios from 'axios'


    const fields = [
        {
            key: "name",
            label: "Режим",
            _style: "min-width:100%;"
        },
        {
            key: "actions",
            label: "Действия",
            _style: "min-width:1%;",
            sorter: false,
            filter: false
        },
    ];

    export default {
        name: 'Times',
        data: function() {
            return {
                apiDatas: [],
                regionDatas: [],
                operatorDatas: [],
                tableFields: ['id', 'name'],
                dataTypes: [],
                form: {
                    action: 'add_time',
                    name: "",
                    data_types: []
                },
                fields,
            }
        },
        methods: {
            getDataFromApi () {
                axios
                    .get(`${axios.defaults.baseURL}?action=get_times`)
                    .then(response => (this.apiDatas = response.data))
            },
            createGrs () {

                let bodyFormData = new FormData();

                bodyFormData.append('action', this.form.action);
                bodyFormData.append('name', this.form.name);
                bodyFormData.append('data_types', this.form.data_types);

                axios({
                    method: "post",
                    url: `${axios.defaults.baseURL}`,
                    data: bodyFormData
                })
                    .then((res) => {
                        this.form.name = "";
                        this.form.data_types = "";
                        //Perform Success Action
                        this.getDataFromApi()
                    })
                    .catch((error) => {
                        console.error(error)
                        // error.response.status Check status code
                    }).finally(() => {
                    // this.getDataFromApi()
                });
            },
            removeTime (item) {

                let bodyFormData = new FormData();

                bodyFormData.append('action', "remove_time");
                bodyFormData.append('id', item.id);

                axios({
                    method: "post",
                    url: `${axios.defaults.baseURL}`,
                    data: bodyFormData
                })
                    .then(() => {
                        //Perform Success Action
                        this.getDataFromApi()
                    })
                    .catch((error) => {
                        console.error(error)
                        // error.response.status Check status code
                    }).finally(() => {
                    // this.getDataFromApi()
                });
            },
        },
        mounted() {
            axios
                .get(`${axios.defaults.baseURL}?action=get_times`)
                .then((response) => {
                  this.apiDatas = response.data
                  console.log(this.apiDatas)
                })
            axios
                .get(`${axios.defaults.baseURL}?action=get_all_datatypes`)
                .then((response) => {
                    response.data.map((el) => {
                        this.dataTypes.push({
                            val: el.id,
                            name: el.name
                        })
                    });
                })

        }
    }
</script>
